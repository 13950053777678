/* Importation des styles Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tes styles personnalisés */
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-pattern {
  background-color: #4a0e4e;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%239C92AC' fill-opacity='0.04'%3E%3Cpath fill-rule='evenodd' d='M11 0l5 20H6l5-20zm42 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM0 72h40v4H0v-4zm0-8h31v4H0v-4zm20-16h20v4H20v-4zM0 56h40v4H0v-4zm63-25a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-30 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-28-8a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zmM56 5a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zm-3 46a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM21 0l5 20H16l5-20zm43 64v-4h-4v4h-4v4h4v4h4v-4h4v-4h-4zM36 13h4v4h-4v-4zm4 4h4v4h-4v-4zm-4 4h4v4h-4v-4zm8-8h4v4h-4v-4z'/%3E%3C/g%3E%3C/svg%3E");
}

.bg-web-pattern {
  background-color: #000000;
  background-image: 
    radial-gradient(circle, transparent 20%, #000000 20%, #000000 80%, transparent 80%, transparent),
    radial-gradient(circle, transparent 20%, #000000 20%, #000000 80%, transparent 80%, transparent) 50px 50px,
    linear-gradient(#111111 2px, transparent 2px) 0 -1px,
    linear-gradient(90deg, #111111 2px, #000000 2px) -1px 0;
  background-size: 100px 100px, 100px 100px, 50px 50px, 50px 50px;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

.custom-text-style {
  font-family: 'Archivo Black', sans-serif;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  -webkit-text-stroke: 2px white;
  color: transparent;
  text-shadow: 
    3px 3px 0 #000,
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}